import React, { Component } from 'react';
import { Row, Col, Card, Icon, Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class RemittancesHome extends Component {

  render() {
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="start" style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={24} lg={24}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/">
                    Home
                  </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/finance">
                    Finance Dashboard
                  </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  Remittance Home {this.props.isAudit ? " (Closed)" : ""}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row gutter={16} style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Card style={{display: "flex", justifyContent: "center"}}>
              <NavLink to={`${this.props.pathname}/locale_churches`}>
                  <Icon type="home" style={{ fontSize: '4em', color: '#08c' }} />
                </NavLink>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Card style={{display: "flex", justifyContent: "center"}}>
                <NavLink to={`${this.props.pathname}/mail`}>
                  <Icon type="mail" style={{ fontSize: '4em', color: '#08c' }} />
                </NavLink>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default RemittancesHome;
