import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, message, Input, DatePicker,
  Radio, Steps, Checkbox, Icon, Table, InputNumber,
} from 'antd';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import moment from 'moment';
import _ from 'lodash';
import querystring from 'querystring';

import financeAPI from '../../apis/financeAPI';
import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;
const { Step } = Steps;
const { RangePicker } = DatePicker;

let gfTimeout;
let gfCurrentValue;

function fetchGf(value, callback) {
  if (gfTimeout) {
    clearTimeout(gfTimeout);
    gfTimeout = null;
  }
  gfCurrentValue = value;

  async function fake() {
    const str = querystring.encode({
      receiptNumber: value,
    });
    financeAPI.getUrl(`/fin/general_forms?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (gfCurrentValue === value) {
          callback(d.data);
        }
      });
  }

  gfTimeout = setTimeout(fake, 300);
}

class GeneralForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gfid: '',
      generalForm: {
        periodStart: '',
        periodEnd: '',
        intAbuloy: [
          { name: "Hain",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
          { name: "Gugol",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
          { name: "Worship",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
          { name: "KNC Abuloy",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
        ],
        intProj: [
          { name: "Daniel's Coffee (Purchase Order)",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
          { name: "Division Fund (PNG & Others)",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
          { name: "KAPI Project: ",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
          { name: "KNC Abuloy",
            boxLocCurrAmt: 0, boxOthCurrAmt: 0, boxOtherCurr: "",
            bankLocAmt: 0, bankLocDateDep: "",
            bankIntAmt: 0, bankIntDateDep: "",
          },
        ],
      },
      current: 0,
      mode: 'new',
      members: [],
      localeChurches: [],
      countries: [],
      history: [],
      stepStatus: [],
      submitting: false,
    };
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi('/ams/locale_churches')
      .then(res => {
        this.setState({ localeChurches: res.data })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async (e, step) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit general form'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const { t, userInfo } = this.props;
    let { generalForm, gfid } = this.state;
    console.log('gfid', gfid)

    let method = ""
    let apiUrl = ""
    if (step === 0 && _.isEmpty(gfid)) {
      method = "POST";
      apiUrl = "/fin/general_forms";
    } else {
      method = "PATCH"
      apiUrl = `/fin/general_forms/${this.state.gfid}`;
    }
    financeAPI.fetchUrl(apiUrl, {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...generalForm,
        userMemberId: userInfo.userMemberId,
        step,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        console.log('response', response)
        if (!_.isEmpty(response)) {
          message.success('General form updated.');
          this.setState({ gfid: response.data._id, submitting: false });
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(t('Error submitting attendance.'));
    });
  };

  handleGfReceiptChange = e => {
    let value = e.target.value;
    console.log('value', value)
    if (value) {
      fetchGf(value, data => {
        let generalForm = {}
        let gfid= "";
        if (!_.isEmpty(data[0])) {
          generalForm = {
            ...data[0],
            receiptNumber: value,
            datePrepared: moment(data[0].datePrepared),
            periodStart: moment(data[0].periodStart),
            periodEnd: moment(data[0].periodEnd),
          };
          gfid = data[0]._id;
        } else {
          generalForm = { ...this.state.generalForm, receiptNumber: value };
        }
        this.setState({ generalForm, gfid });
      });
    } else {
      this.setState({ generalForm: {} });
    }
  };

  updateGeneralForm = async (updatedInfo) => {
    const currentInfo = this.state.generalForm;
    this.setState({
      generalForm: { ...currentInfo, ...updatedInfo }
    });
  };

  updateCollection = (index, collectionType, newInfo) => {
    if (collectionType === "intAbuloy") {
      let currentItems = this.state.generalForm.intAbuloy;
      let currentItem = currentItems[index];
      currentItem = { ...currentItem, ...newInfo }
      currentItems[index] = currentItem;
      this.updateGeneralForm({ intAbuloy : currentItems });

    } else if (collectionType === "natProj") {
      let currentItems = this.generalForm.natProj;
      let currentItem = currentItems[index];
      currentItem = { ...currentItem, ...newInfo }
      currentItems[index] = currentItem;
      this.updateGeneralForm({ natProj : currentItems });

    } else if (collectionType === "locProj") {
      let currentItems = this.generalForm.locProj;
      let currentItem = currentItems[index];
      currentItem = { ...currentItem, ...newInfo }
      currentItems[index] = currentItem;
      this.updateGeneralForm({ locProj : currentItems });
    }
  }

  next = (e) => {
    let { current } = this.state;
    this.handleSubmit(e, current);
    current = current + 1
    this.setState({ current });
  }

  prev = () => {
    let { current } = this.state;
    current = current - 1;
    this.setState({ current });
  }

  render() {
    const {
      generalForm, localeChurches, current,
    } = this.state;
    const { t } = this.props;
    const {
      intAbuloy,
      isWayOfKnowingBibleExposition, isWayOfKnowingBibleStudy,
      formerReligion, baptismDate,
      localeChurch, churchRelatives,
      gathering, receiptNumber, datePrepared, periodStart, periodEnd,
    } = generalForm;

    const intAbuloyData = [];
    let i = 0;
    intAbuloy.forEach(item => {
      intAbuloyData.push({
        key: i,
        index: i,
        name: item.name,
      });
      i++;
    })

    const columns = [
      {
        title: 'Collection',
        key: 'name',
        width: 170,
        fixed: 'left',
        render: (text, record) => (
          <span>{record.name}</span>
        ),
      },
      {
        title: 'No. of Env.',
        key: 'env',
        width: 100,
        render: (text, record) => (
          <InputNumber
            onChange={(value) => this.updateCollection(record.index, "intAbuloy", { noOfEnvs: value })}
            value={
              this.state.generalForm.intAbuloy[record.index]
                ? this.state.generalForm.intAbuloy[record.index].noOfEnvs
                : null
            }
            size="small"
            style={{width: 50}}
            min={1}
          />
        ),
      },
      {
        title: 'Amount',
        children: [
          {
            title: 'Through Box',
            key: 'box',
            children: [
              {
                title: '(1) Locale Currency',
                key: 'lcr',
                width: 150,
                render: (text, record) => (
                  <InputNumber
                    onChange={(value) => this.updateCollection(record.index, "intAbuloy", { boxLocCurrAmt: value })}
                    value={
                      this.state.generalForm.intAbuloy[record.index]
                        ? this.state.generalForm.intAbuloy[record.index].boxLocCurrAmt
                        : null
                    }
                    size="small"
                    min={1}
                  />
                ),
              },
              {
                title: 'Other Currency',
                key: 'loc',
                width: 150,
                render: (text, record) => (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <InputNumber
                      onChange={(value) => this.updateCollection(record.index, "intAbuloy", { boxOthCurrAmt: value })}
                      value={
                        this.state.generalForm.intAbuloy[record.index]
                          ? this.state.generalForm.intAbuloy[record.index].boxOthCurrAmt
                          : null
                      }
                      style={{width: 80}}
                      size="small"
                      min={1}
                    />
                    <Input
                      onChange={(e) => this.updateCollection(record.index, "intAbuloy", { boxOtherCurr: e.target.value })}
                      value={
                        this.state.generalForm.intAbuloy[record.index]
                          ? this.state.generalForm.intAbuloy[record.index].boxOtherCurr
                          : null
                      }
                      style={{width: 50}}
                      size="small"
                    />
                  </div>
                ),
              },
            ],
          },
          {
            title: 'Through Bank Account',
            children: [
              {
                title: '(2) Locale Account',
                key: 'lba',
                width: 150,
                render: (text, record) => (
                  <InputNumber
                    onChange={(value) => this.updateCollection(record.index, "intAbuloy", { bankLocAmt: value })}
                    value={
                      this.state.generalForm.intAbuloy[record.index]
                        ? this.state.generalForm.intAbuloy[record.index].bankLocAmt
                        : null
                    }
                    size="small"
                    min={1}
                  />
                ),
              },
              {
                title: 'Date Deposited',
                key: 'lbd',
                width: 150,
                render: (text, record) => (
                  <DatePicker
                    onChange={(value) => this.updateCollection(record.index, "intAbuloy", { bankLocDateDep: value })}
                    value={
                      !_.isEmpty(this.state.generalForm.intAbuloy[record.index])
                      && !_.isEmpty(this.state.generalForm.intAbuloy[record.index].bankLocDateDep)
                        ? typeof this.state.generalForm.intAbuloy[record.index].bankLocDateDep === "string"
                          ? moment(this.state.generalForm.intAbuloy[record.index].bankLocDateDep)
                          : this.state.generalForm.intAbuloy[record.index].bankLocDateDep
                        : null
                    }
                    size="small"
                  />
                ),
              },
              {
                title: '(3) MCGI MUFG #1',
                key: 'iba',
                width: 150,
                render: (text, record) => (
                  <InputNumber
                    onChange={(value) => this.updateCollection(record.index, "intAbuloy", { bankIntAmt: value })}
                    value={
                      this.state.generalForm.intAbuloy[record.index]
                        ? this.state.generalForm.intAbuloy[record.index].bankIntAmt
                        : null
                    }
                    size="small"
                    min={1}
                  />
                ),
              },
              {
                title: 'Date Deposited',
                key: 'ibd',
                width: 150,
                render: (text, record) => (
                  <DatePicker
                    onChange={(value) => this.updateCollection(record.index, "intAbuloy", { bankIntDateDep: value })}
                    value={
                      !_.isEmpty(this.state.generalForm.intAbuloy[record.index])
                      && !_.isEmpty(this.state.generalForm.intAbuloy[record.index].bankIntDateDep)
                        ? typeof this.state.generalForm.intAbuloy[record.index].bankIntDateDep === "string"
                          ? moment(this.state.generalForm.intAbuloy[record.index].bankIntDateDep)
                          : this.state.generalForm.intAbuloy[record.index].bankIntDateDep
                        : null
                    }
                    size="small"
                  />
                ),
              },
            ],
          },
        ],
      },
    ];

    const steps = [
      {
        key: "report-info",
        icon: <Icon type="form" />,
        content:
        <div>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={4}>
              <h3>Report Information</h3>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }} >
              <Form layout="horizontal"
                labelCol={ { xs: { span: 24 }, sm: { span: 10 }}}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 12 }}}
              >
                <Form.Item label="Gathering">
                  <Input
                    onChange={(e) => this.updateGeneralForm({ gathering: e.target.value })}
                    value={gathering}
                  />
                </Form.Item>
                <Form.Item label="Receipt Number">
                  <Input
                    onChange={this.handleGfReceiptChange}
                    defaultValue={receiptNumber}
                  />
                </Form.Item>
                <Form.Item label="Date Prepared">
                  <DatePicker
                    onChange={(value) => this.updateGeneralForm({ datePrepared: value })}
                    value={datePrepared ? datePrepared : null}
                  />
                </Form.Item>
                <Form.Item label="Period Covered">
                  <RangePicker
                    format={'YYYY/MM/DD'}
                    onChange={(date, dateString) => this.updateGeneralForm({ periodStart: dateString[0], periodEnd: dateString[1]})}
                    value={[
                      periodStart ? moment(periodStart, 'YYYY/MM/DD') : null,
                      periodEnd ? moment(periodEnd, 'YYYY/MM/DD') : null,
                    ]}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      },
      {
        key: "international",
        icon: <Icon type="global" />,
        content:
        <div>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={4}>
              <h3>International Collections</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={{span: 16, offset: 4 }} >
              <Table
                columns={columns}
                dataSource={intAbuloyData}
                bordered
                size="middle"
                scroll={{ x: 'calc(700px + 50%)', y: 270 }}
                pagination={false}
              />
            </Col>
          </Row>
        </div>
        ,
      },
      {
        key: "national",
        icon: <Icon type="flag" />,
        content:
        <div>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={4}>
              <h3>National Collections</h3>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }}>
              <Form layout="horizontal"
                labelCol={ { xs: { span: 24 }, sm: { span: 10 }}}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 12 }}}
              >
                <Form.Item label="Ways of know the truth">
                  <Checkbox
                    onChange={e => this.updateGeneralForm({ isWayOfKnowingBibleExposition: e.target.checked })}
                    defaultChecked={isWayOfKnowingBibleExposition ? isWayOfKnowingBibleExposition : null}
                  >Bible Exposition</Checkbox>
                  <br/>
                  <Checkbox
                    onChange={e => this.updateGeneralForm({ isWayOfKnowingBibleStudy: e.target.checked })}
                    defaultChecked={isWayOfKnowingBibleStudy ? isWayOfKnowingBibleStudy : null}
                  >Bible Study</Checkbox>
                  <br/>
                </Form.Item>
                <Form.Item label="Former Religion">
                  <Input
                    onChange={(e) => this.updateGeneralForm({ formerReligion: e.target.value })}
                    defaultValue={formerReligion ? formerReligion : null}
                  />
                </Form.Item>
                <Form.Item label="Date and Time of Baptism">
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value) => this.updateGeneralForm({ baptismDate: value })}
                    defaultValue={baptismDate ? moment(baptismDate) : null}
                  />
                </Form.Item>
                <Form.Item label="Locale:">
                  <Select
                    showSearch
                    placeholder="Select a locale"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.updateGeneralForm({ localeChurch: value })}
                    defaultValue={localeChurch ? localeChurch : null}
                  >
                    {localeChurches.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Relatives in the Church">
                  <Input
                    onChange={(e) => this.updateGeneralForm({ churchRelatives: e.target.value })}
                    defaultValue={churchRelatives ? churchRelatives : null}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      },
      {
        key: "locale",
        icon: <Icon type="home" />,
        content:
        <div>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={4}>
              <h3>Locale Collections</h3>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }}>
              <Form layout="horizontal"
                labelCol={ { xs: { span: 24 }, sm: { span: 10 }}}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 12 }}}
              >
                <Form.Item label="Language/s Spoken">
                  <Input
                    onChange={(e) => this.updateGeneralForm({ spokenLanguages: e.target.value })}
                    defaultValue={generalForm.spokenLanguages}
                  />
                </Form.Item>
                <Form.Item label="Skills">
                  <Input
                    onChange={(e) => this.updateGeneralForm({ skills: e.target.value })}
                    defaultValue={generalForm.skills}
                  />
                </Form.Item>
                <Form.Item
                  label="Do you own a company or established business?"
                >
                  <Radio.Group
                    onChange={(e) => this.updateGeneralForm({ hasBusiness: e.target.value })}
                    defaultValue={generalForm.hasBusiness}
                  >
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      },
    ];

    let disableNext = false;
    if (this.state.current === 0) {
      if (!gathering || !receiptNumber || !datePrepared || !periodStart || !periodEnd) {
        disableNext = true;
      }
    }

    const disableSubmit = false;
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={6}>
              <h2>{t("ASIA & OCEANIA DIVISION")}</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={3}>
              <h3>{t("General Form")}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Steps current={current}>
                {steps.map(item => (
                  <Step key={item.key} title={item.title} icon={item.icon} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row type="flex" justify="start" style={{ marginTop: 5, marginBottom: 5 }}>
            <Col
              xs={{span: 4, offset: 20 }}
              sm={{span: 4, offset: 20 }}
              md={24}
              lg={{span: 4, offset: 13 }}
            >
              <div className="steps-action">
                {current > 0 && (
                  <Button onClick={this.prev}>
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    style={current > 0 ? { marginLeft: 8 } : { marginLeft: 0 }}
                    onClick={this.next}
                    disabled={disableNext}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    style={{ marginLeft: 8 }}
                    loading={this.state.submitting}
                    onClick={this.handleSubmit}
                    disabled={disableSubmit}
                  >
                    {t("Submit")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24}>
              {steps[current].content}
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col
              xs={{span: 4, offset: 20 }}
              sm={{span: 4, offset: 20 }}
              md={24}
              lg={{span: 4, offset: 13 }}
            >
              <div className="steps-action">
                {current > 0 && (
                  <Button onClick={this.prev}>
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    style={current > 0 ? { marginLeft: 8 } : { marginLeft: 0 }}
                    onClick={this.next}
                    disabled={disableNext}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    style={{ marginLeft: 8 }}
                    loading={this.state.submitting}
                    onClick={this.handleSubmit}
                    disabled={disableSubmit}
                  >
                    {t("Finish")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GeneralForm);