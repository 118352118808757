import React, { Component } from 'react';
import {
  Form, Button, DatePicker, Select, Switch, Input, message
} from 'antd';
import moment from "moment";

import amsAPI from '../../apis/amsAPI';
import disablePastDates from '../../helpers/functions';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Activity.css';

const Option = Select.Option;

class NewActivity extends Component {
  state = {
    record: {},
    ministries: [],
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { record } = this.state;
    await amsAPI.fetchUrl(`/ams/activities`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...record}),
    })
    .then(res => {
      if (res.status === 200) {
        message.success("Activity successfully created.");
        this.props.history.push('/activities');
      } else if (res.status === 422) {
        message.error("Activity already registered.");
      }
    })
    .catch(err => {
      message.error("Error in registering activity.");
      console.log(err);
    });

  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const { ministries } = this.state;
    const activityEntries = Object.entries(constants.activities);
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Activity:">
          <Select
            showSearch
            placeholder="Select an activity"
            dropdownMatchSelectWidth={false}
            style={{ width: 240 }}
            onChange={value => this.updateRecord({ name: value })}
          >
            {activityEntries.map(([id, name]) =>
              <Option key={id} value={id}>{name}</Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Ministry">
          <Select
            placeholder="Select a ministry"
            dropdownMatchSelectWidth={false}
            style={{ width: 240 }}
            onChange={value => this.updateRecord({ ministry: value })}
          >
            {ministries.map(item => {
              return <Option key={item._id} value={item._id}>{item.name}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Start Time:">
          <DatePicker
            showToday
            format="YYYY-MM-DD h:mm a"
            showTime={{ use12Hours: true, format: "h:mm a" }}
            placeholder="Start Date Time"
            onChange={(value) => this.updateRecord({ startDateTime: moment(value).startOf('minute') })}
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item label="Close Time:">
          <DatePicker
            showToday
            format="YYYY-MM-DD h:mm a"
            showTime={{ use12Hours: true, format: "h:mm a" }}
            placeholder="End Date Time"
            onChange={(value) => this.updateRecord({ endDateTime: moment(value).startOf('minute') })}
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item
          label="Link:"
        >
          <Input
            onChange={(e) => this.updateRecord({ link: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Is active?">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => this.updateRecord({ active: checked })}
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Register</Button>
          <p>{this.state.responseToPost}</p>
        </Form.Item>
      </Form>
    );
  }
}

export default NewActivity;
