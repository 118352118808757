import React, { Component } from 'react';
import { Col, Row, Table, Spin, Button, Icon, Checkbox } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';
import _ from 'lodash';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Report.css';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: key => <span>{key}</span>
  },
  {
    title: 'Gathering',
    dataIndex: 'gatheringId',
    key: 'gatheringId',
    render: gatheringId => <span>{constants.gatherings[gatheringId.name]} ({gatheringId.type})</span>,
  },
  {
    title: 'Submission',
    dataIndex: 'submissionDateTime',
    key: 'submissionDateTime',
    render: (text, record) => (
      <span>
        {record.submissionDateTime ?
          `${moment(record.submissionDateTime).format("MMM.DD(ddd), h:mmA")}`
          :
          null
        }
      </span>
    ),
  },
  {
    title: 'IP Address',
    dataIndex: 'ipAddress',
    key: 'ipAddress',
    render: (text, record) => (
      <span>{record.ipAddress}</span>
    ),
  },
  {
    title: 'Member',
    dataIndex: 'memberId',
    key: 'memberId',
    render: memberId => <span>{memberId ? memberId.name : ""}</span>,
  },
  {
    title: 'Guest/s',
    dataIndex: 'guestAttendance',
    key: 'guestAttendance',
    render: (text, record) => (
      <span>
        {record.guestAttendance ?
          record.guestAttendance.map(item => {
            return <div>{item.religion + " : " + item.count}</div>
          })
          :
          ""
        }
      </span>
    ),
  },
];

class AttendanceReportByGathering extends Component {
  state = {
    gathering: {},
    attendance: [],
    gatheringTypes: [],
    isGenerateIds: false,
    isGenerateIdsAll: false,
    loadingAttendance: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.getAttendance()
      .then(res => {
        let modResult = res.result.sort((a, b) => {
          if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
          return a.memberId.localeChurchId.name - b.memberId.localeChurchId.name;
        });
        this.setState({ attendance: modResult, loadingAttendance: false })
      })
      .catch(err => console.log(err));

    const query = qs.parse(this.props.location.search);
    const { gatheringId } = query;
    this.getGatheringInfo(gatheringId)
      .then(res => this.setState({ gathering: res.gathering }))
      .catch(err => console.log(err));
  }

  getAttendance = async () => {
    this.setState({ loadingAttendance: true })
    const query = qs.parse(this.props.location.search);
    const { userMemberId } = this.props.userInfo;
    const { gatheringId, nationality } = query;
    const { localeChurchId } = this.props;
    const q = { gatheringId, localeChurchId, nationality, userMemberId };
    const response = await amsAPI.getUrl(`/ams/locale_attendance/by_gathering_lc?${qs.stringify(q)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGatheringInfo = async (gatheringId) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async () => {
    this.setState({ loadingMembers: true });
    const response = await amsAPI.getUrl(`/ams/members`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendance()
      .then(res => {
        let modResult = res.result.sort((a, b) => {
          if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
          return a.memberId.localeChurchId.name - b.memberId.localeChurchId.name;
        });
        this.setState({ attendance: modResult, loadingAttendance: false })
      })
      .catch(err => console.log(err));
  }

  downloadTxtFile = async () => {
    const {
      attendance, gathering, gatheringTypes,
      isGenerateIds, isGenerateIdsAll,
    } = this.state;
    const gatherings = {};
    gatheringTypes.forEach(item => {
      gatherings[item.code] = item.name;
    })
    let modResult = attendance.sort((a, b) => {
      if (!a.memberId || !b.memberId || !a.memberId.localeChurchId || !b.memberId.localeChurchId) return -1;
      return a.memberId.localeChurchId.name.localeCompare(b.memberId.localeChurchId.name);
    });

    let outputTxt = "";
    const reportDate = moment(gathering.startDateTime).format("h:mmA | dddd | MMMM DD, YYYY");
    outputTxt = outputTxt + "ATTENDANCE REPORT FOR HOOKUP\r\n";
    outputTxt = outputTxt + gatherings[gathering.name] + "\r\n";
    outputTxt = outputTxt + reportDate + "\r\n";

    if (isGenerateIdsAll) {
      outputTxt = outputTxt + "\r\n";
      modResult.forEach(item => {
        outputTxt = outputTxt + item.memberId.churchId + "\r\n";
      });

    } else if (gathering.name === "kgm") {
      let localeChurch = "";
      let counter = 1;

      let kapiAssocs = modResult.filter(x => x.isKapiAssociate);
      let nonKapiAssocs = modResult.filter(x => !x.isKapiAssociate);

      outputTxt = outputTxt + "\r\n";
      outputTxt = outputTxt + "### KAPI ASSOCIATES ###\r\n";

      kapiAssocs.forEach(item => {
        const { memberId, submissionDateTime } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== memberId.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = memberId.localeChurchId.name;
          outputTxt = outputTxt + memberId.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let member = `  ${counter}. ${memberId.name}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        outputTxt = outputTxt + member + "\r\n";
        counter++;
      });

      outputTxt = outputTxt + "\r\n";
      outputTxt = outputTxt + "### NON-KAPI ASSOCIATES ###\r\n";

      localeChurch = "";
      counter = 1;
      nonKapiAssocs.forEach(item => {
        const { memberId, submissionDateTime } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== memberId.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = memberId.localeChurchId.name;
          outputTxt = outputTxt + memberId.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let member = `  ${counter}. ${memberId.name}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        outputTxt = outputTxt + member + "\r\n";
        counter++;
      });

    } else {
      let localeChurch = "";
      let counter = 1;
      let guests = {};
      modResult.forEach(item => {
        const { memberId, submissionDateTime, guestAttendance } = item;
        const submissionTime = moment(submissionDateTime).format("h:mmA");
        if (localeChurch !== memberId.localeChurchId.name) {
          counter = 1;
          outputTxt = outputTxt + "\r\n";
          localeChurch = memberId.localeChurchId.name;
          outputTxt = outputTxt + memberId.localeChurchId.name.toUpperCase() + "\r\n";
        }

        let member = "";
        if (isGenerateIds && memberId.isVisiting) {
          member = `  ${counter}. ${memberId.churchId}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        } else {
          member = `  ${counter}. ${memberId.name}${memberId.isVisiting ? " (VB)" : ""} - ${submissionTime}`;
        }
        outputTxt = outputTxt + member + "\r\n";

        if (!_.isEmpty(guestAttendance)) {
          guestAttendance.forEach(item => {
            if (guests[item.religion]) {
              guests[item.religion] = guests[item.religion] + item.count;
            } else {
              guests[item.religion] = item.count;
            }
          })
        }
        counter++;
      });

      if (!_.isEmpty(guests)) {
        outputTxt = outputTxt + "\r\n";
        outputTxt = outputTxt + "  Guest/s \r\n";
        let counter = 1;
        Object.entries(guests).forEach(([religion, count]) => {
          const guestCount = `    ${counter}. ${religion} : ${count}`;
          outputTxt = outputTxt + guestCount + "\r\n";
          counter++;
        })
      }
    }

    let channelSummary = { }
    modResult.forEach(item => {
      let { channel, otherChannel } = item;
      if (channel) {
        let channelKey = channel === "others" ? `${channel} (${otherChannel})` : channel;
        if (channelSummary[channelKey]) {
          channelSummary[channelKey] = channelSummary[channelKey] + 1;
        } else {
          channelSummary[channelKey] = 1
        }
      }
    });

    outputTxt = outputTxt + "\r\n";
    outputTxt = outputTxt + "CHANNEL SUMMARY\r\n";
    const channelEntries = Object.entries(channelSummary);
    channelEntries.forEach(([key, value]) => {
      outputTxt = outputTxt + `  ${key[0].toUpperCase() + key.substring(1)}: ${value}\r\n`;
    })

    const element = document.createElement("a");
    const file = new Blob([outputTxt], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Attendance Report_${moment().format('YYYYMMDD_hhmmss')}.txt`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    const { attendance, loadingAttendance } = this.state;
    const loading = loadingAttendance;
    let modResult = [];
    if (attendance.length > 0) {
      let i = 1;
      attendance.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Gathering Attendance Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={14} style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                onClick={this.downloadTxtFile}
              > Generate Text</Button>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Checkbox
                  onChange={(e) => this.setState({ isGenerateIdsAll: e.target.checked })}
                  disabled={this.state.isGenerateIds}
                >Generate church IDs only for ALL</Checkbox><br/>
                <Checkbox
                  onChange={(e) => this.setState({ isGenerateIds: e.target.checked })}
                  disabled={this.state.isGenerateIdsAll}
                >Generate church IDs only for visiting brethren</Checkbox>
              </div>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={14} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={14}>
              {(modResult && modResult.length === 0) ?
                <h2>{`No attendance available for selected gathering.`}</h2>
              :
                <div>
                  <h3>{`Here's the attendance for the selected gathering:`}</h3>
                  <Table pagination={false} columns={columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default AttendanceReportByGathering;
