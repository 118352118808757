import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Route } from 'react-router-dom';
import { withRouter } from "react-router";
import { Button, Col, Layout, Row, Menu, Icon, message } from 'antd';
import ReactGA from 'react-ga';
import { GoogleLogout, GoogleLogin } from 'react-google-login';
import UrlPattern from 'url-pattern';
import _ from 'lodash';

import {
  Home, ReportsHomeByGathering, FinanceHome,
  RemittancesHome, RemittanceReportsHome, AmsHome,
  MinistryHome,
} from './homes';
import { AttendanceReportByGathering, AttendanceReportByGatheringElders, PdfLocaleReport } from './reports';
import { Login, NewLocaleReport, EditLocaleReport, DraftLocaleReport } from './form';
import { GeneralForms, NewGeneralForm } from './general_forms';
import { LocaleReports } from './list';
import {
  NewRemittance, EditRemittance, Remittances, ShowRemittance, RemittanceHistory,
} from './finance/remittance';
import {
  RemittanceReceiptsReport, RemittanceForActionReport,
} from './finance/reports';
import {
  Gatherings, CreateGathering, EditGathering, GatheringAttendees, AttendanceApproval
} from './gathering';
import {
  Activities, ActivityAttendees, NewActivity, EditActivity,
} from './activity'
import { GenerateToken } from './security';
import { Unauthorized } from './errors';

import amsAPI from '../apis/amsAPI';
import * as constants from '../helpers/constants';

import 'antd/dist/antd.css';
import './ContentWrapper.css';

ReactGA.initialize('UA-146791855-1');

const {
  Header, Content,
} = Layout;

class Wrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props){
    super(props);
    this.state = {
      remittanceInfo: {},
      generalForm: {},
      userInfo: {},
      roles: [],
      isLocaleMembersPage: false,
      isSignedIn: false,
      isGoogleLogin: false,
      services: {
        ams: {
          isAvailable: _.isEmpty(process.env.REACT_APP_AMS_API) ? false : true,
          url: process.env.REACT_APP_AMS_API,
        },
        fin: {
          isAvailable: _.isEmpty(process.env.REACT_APP_FINANCE_API) ? false : true,
          url: process.env.REACT_APP_FINANCE_API,
        },
        sys: {
          isAvailable: _.isEmpty(process.env.REACT_APP_SYSTEM_API) ? false : true,
          url: process.env.REACT_APP_SYSTEM_API,
        },
        tic: {
          isAvailable: _.isEmpty(process.env.REACT_APP_TICKETS_API) ? false : true,
          url: process.env.REACT_APP_TICKETS_API,
        },
      },
    };
    this.goBack = this.goBack.bind(this);
    this.goToNew = this.goToNew.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
 }

  goBack() {
    const pathname = this.props.location.pathname;
    const isListPage = pathname.substring(1).match(
        /^(locale_reports|reports|activities)$/,
      );
    const pattern = new UrlPattern('/locale_reports/:id/edit');
    const isLocaleReportsEditPage = pattern.match(pathname);
    const isFinancePage = pathname.substring(1).match(
      /^(remittances|remittances_closed)$/,
    );

    if (isListPage) {
      this.props.history.push("/");
    } else if (isFinancePage) {
      this.props.history.push("/finance");
    } else if (isLocaleReportsEditPage) {
      this.props.history.push("/locale_reports");
    } else {
      this.props.history.goBack();
    }
  }

  goToNew() {
    const pathname = this.props.location.pathname;
    const newUrl = pathname + '/new';
    this.props.history.push(newUrl);
  }

  goHome() {
    this.props.history.push("/");
  }

  onLoginSuccess = info => {
    amsAPI.fetchUrl(`/ams/login/officer`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify({
        email: info.profileObj.email,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const body = await res.json();
        message.success("Successfully logged in.")
        this.setState({
          isSignedIn: true,
          isGoogleLogin: true,
          userInfo: {
            localeChurchId: body.localeChurchId,
            memberId: body._id,
            roles: body.roles,
            name: body.name,
            isAdmin: body.isAdmin,
            userMemberId: body._id,
          },
          localeChurchId: body.localeChurchId,
          memberId: body._id,
          roles: body.roles,
          err: null
        })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error logging in.');
    });
  }

  setLogin = (info) => {
    this.setState({
      ...info,
      isSignedIn: true,
      err: null
    });
  }

  logoutUser = () => {
    this.setState({
      isSignedIn: false,
      isGoogleLogin: false,
    });
  }

  onLoginFailed = (err) => {
    console.log('login failed', err)
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  onLogoutSuccess = () => {
    this.setState({
      isSignedIn: false,
    })
  }

  onLogoutFailed = (err) => {
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  updateRemittanceInfo = async (updatedInfo) => {
    const currentInfo = this.state.remittanceInfo;
    this.setState({
      remittanceInfo: { ...currentInfo, ...updatedInfo }
    });
  };

  updateGeneralForm = async (updatedInfo) => {
    const currentInfo = this.state.generalForm;
    this.setState({
      generalForm: { ...currentInfo, ...updatedInfo }
    });
  };

  render() {
    const pathname = this.props.location.pathname;
    const { isSignedIn, isGoogleLogin } = this.state;
    const isAdd = pathname.indexOf('new') >= 0;
    const isListPage = pathname.substring(1).match(
      /^(locale_reports|reports|general_forms|activities)$/,
    );
    const displayAddButton = isListPage;

    const auditRemittanceStatuses = ["received", "duplicate", "cancelled"];
    const remittanceStatuses = Object.keys(constants.remittanceStatuses);
    const openRemitanceStatuses = remittanceStatuses.filter(item => !auditRemittanceStatuses.includes(item));

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {(pathname !== "/") &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goHome}
                  >
                    <Icon type="home" />
                  </Button>
                }
                {(pathname !== "/" && isSignedIn) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goBack}
                    style={{ marginLeft: "5px" }}
                  >
                    Back
                  </Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="1"
              >
                {isSignedIn &&
                  <Button
                    type="default"
                    size="large"
                    disabled={isAdd}
                    onClick={this.goToNew}
                    style={
                      {
                        display: (displayAddButton) ? "inherit" : "none",
                        marginRight: '10px',
                      }
                    }
                  >Add</Button>
                }
                {(!isSignedIn && pathname !== "/") &&
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={this.onLoginSuccess}
                    onFailure={this.onLoginFailed}
                    cookiePolicy={'single_host_origin'}
                  />
                }
                {(isSignedIn && isGoogleLogin) &&
                  <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                    onFailure={this.onLogoutFailed}
                  />
                }
                {(isSignedIn && !isGoogleLogin) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.logoutUser}
                  >Logout</Button>
                }
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Home
                          {...props}
                          userInfo={this.state.userInfo}
                          localeChurchId={this.state.localeChurchId}
                        />
                        :
                        <Login
                          {...props}
                          setLogin={this.setLogin}
                          onLoginSuccess={this.onLoginSuccess}
                          onLoginFailed={this.onLoginFailed}
                        />
                    }
                  />

                  <Route exact path="/ams"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AmsHome
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/reports"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ReportsHomeByGathering
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/locale_attendance_by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByGathering
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_gathering_absentees"
                    render={(props) =>
                      this.state.isSignedIn ?
                      <AttendanceReportByGatheringElders
                        {...props}
                        localeChurchId={this.state.localeChurchId}
                        userInfo={this.state.userInfo}
                      />
                      :
                      <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings"
                    render={(props) =>
                      this.state.isSignedIn ? <Gatherings {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateGathering {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditGathering {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/attendees"
                    render={(props) =>
                      this.state.isSignedIn ? <GatheringAttendees {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/approvals"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceApproval {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/locale_reports"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <LocaleReports
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/locale_reports/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewLocaleReport
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/locale_reports/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditLocaleReport
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/locale_reports/:_id/pdf"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <PdfLocaleReport
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/finance"
                    render={(props) =>
                      this.state.isSignedIn ? <FinanceHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittancesHome
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          pathname={"/remittances"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/mail"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          remitMethod={"mail"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/pickup"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          remitMethod={"worker-pickup"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          isLocaleChurchAccount={true}
                          remitMethod={"bank"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittancesHome
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          pathname={"/remittances_closed"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          isLocaleChurchAccount={true}
                          remitMethod={"bank"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/mail"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          remitMethod={"mail"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/pickup"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          remitMethod={"worker-pickup"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewRemittance
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditRemittance
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/show"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ShowRemittance
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/generate"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GenerateToken
                          {...props}
                          model={"remittance"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceHistory
                          {...props}
                          userInfo = {this.state.userInfo}
                          model={"remittance"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceReportsHome
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports/receipts"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceReceiptsReport
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports/for_action"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceForActionReport
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/old_national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"old national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/old_kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"old kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/old_national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"old national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/old_kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo = {this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"old kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/general_forms"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GeneralForms
                          {...props}
                          userInfo = {this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/general_forms/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewGeneralForm
                          {...props}
                          userInfo = {this.state.userInfo}
                          generalForm = {this.state.generalForm}
                          updateGeneralForm = {this.updateGeneralForm}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/drafts/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <DraftLocaleReport
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/ministry_home"
                    render={(props) =>
                      this.state.isSignedIn ? <MinistryHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/activities"
                    render={(props) =>
                      this.state.isSignedIn ? <Activities {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/activities/:_id/attendees"
                    render={(props) =>
                      this.state.isSignedIn ? <ActivityAttendees {...props} /> : <Unauthorized />
                    }
                  />

                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Wrapper);
