import React, { Component } from 'react';
import {
  Form, Button, DatePicker, Select, Checkbox, message
} from 'antd';
import moment from "moment";

import amsAPI from '../../apis/amsAPI';
import disablePastDates from '../../helpers/functions';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './CreateForm.css';

const Option = Select.Option;

class CreateGathering extends Component {
  state = {
    response: [],
    name: '',
    type: '',
    startDateTime: null,
    endDateTime: null,
    isActive: false,
    responseToPost: '',
  };

  handleSubmit = async e => {
    e.preventDefault();
    await amsAPI.fetchUrl(`/ams/gatherings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        name: this.state.name,
        type: this.state.type,
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        active: this.state.isActive,
      }),
    })
    .then(res => {
      if (res.status === 200) {
        message.success("Gathering successfully created.");
        this.props.history.push('/gatherings');
      } else if (res.status === 422) {
        message.error("Gathering already registered.");
      }
    })
    .catch(err => {
      message.error("Error in registering gathering.");
      console.log(err);
    });
    
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const gatheringEntries = Object.entries(constants.gatherings);
    const gatheringTypes = Object.entries(constants.gatheringTypes);
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Gathering:">
          <Select
              showSearch
              placeholder="Select a gathering"
              dropdownMatchSelectWidth={false}
              style={{ width: 240 }}
              onChange={value => this.setState({ name: value })}
            >
              {gatheringEntries.map(([id, name]) => 
                <Option key={id} value={id}>{name}</Option>
              )}
          </Select>
        </Form.Item>
        <Form.Item label="Type:">
          <Select
              showSearch
              placeholder="Select a gathering type"
              dropdownMatchSelectWidth={false}
              style={{ width: 240 }}
              onChange={value => this.setState({ type: value })}
            >
              {gatheringTypes.map(([id, name]) => 
                <Option key={id} value={id}>{name}</Option>
              )}
          </Select>
        </Form.Item>
        <Form.Item label="Start Date:">
          <DatePicker
            showToday
            format="YYYY-MM-DD h:mm a"
            showTime={{ use12Hours: true, format: "h:mm a" }}
            placeholder="Start Date Time"
            value={this.state.startDateTime}
            onChange={(value) => this.setState({ startDateTime: moment(value).startOf('minute') })}
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item label="End Date:">
          <DatePicker
            showToday
            format="YYYY-MM-DD h:mm a"
            showTime={{ use12Hours: true, format: "h:mm a" }}
            placeholder="End Date Time"
            value={this.state.endDateTime}
            onChange={(value) => this.setState({ endDateTime: moment(value).endOf('minute') })}
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item label="Other Info:">
          <Checkbox
            onChange={(e) => this.setState({ isActive: e.target.checked })}
          >Active</Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Register</Button>
          <p>{this.state.responseToPost}</p>
        </Form.Item>
      </Form>
    );
  }
}

export default CreateGathering;
