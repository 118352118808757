import React, { Component } from 'react';
import { PageHeader, Row, Col, Icon, Card } from 'antd';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class Home extends Component {

  render() {
    const { name, roles } = this.props.userInfo;
    const isDisplayAms = roles.reduce((total, item) => ['llc', 'lsc', 'lgs', 'lgsc'].includes(item) ? total+1 : total, 0);
    const displayLcReports = roles.reduce((total, item) => ['llc'].includes(item) ? total+1 : total, 0);
    const displayFinReports = roles.reduce((total, item) => ['lgs', 'lrm', 'ltr', 'llc' ].includes(item) ? total+1 : total, 0);
    const displayGatherings = roles.reduce((total, item) => ['llc'].includes(item) ? total+1 : total, 0);
    const isDisplayMinistry = roles.reduce((total, item) => ['lcc'].includes(item) ? total+1 : total, 0);

    return (
      <PageHeader
        title={`Welcome ${name || "Guest"}, what would you like to do today?`}
      >
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              {/* {isDisplayAms ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/ams">
                      <span style={{ fontSize: '4em', color: '#08c' }}>AMS</span>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              } */}
              {isDisplayMinistry ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/ministry_home">
                      <Icon type="crown" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {displayGatherings ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/gatherings">
                      <Icon type="calendar" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {isDisplayAms ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/reports">
                      <Icon type="table" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {displayLcReports ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/locale_reports">
                      <Icon type="form" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {displayFinReports ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/finance">
                      <Icon type="account-book" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/general_forms">
                    <Icon type="book" style={{ fontSize: '4em', color: '#08c' }}/>
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default Home;
