import React, { Component } from 'react';
import {
  Form, Button, DatePicker, Select, Row, Col, Spin, Switch, Input, message
} from 'antd';

import moment from 'moment';
import _ from 'lodash';

import amsAPI from '../../apis/amsAPI';
import disablePastDates from '../../helpers/functions';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './CreateForm.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};

class LocaleChurchLink extends Component {
  state = {
    isLocaleChurchInputted: false,
    isLinkInputted: false,
  }
  componentDidMount() {
    let { localeLink } = this.props;
    if (!_.isEmpty(localeLink)) {
      if (localeLink.localeChurch) this.setState({ isLocaleChurchInputted: true });
      if (localeLink.link) this.setState({ isLinkInputted: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let { localeLink } = this.props;
      if (!_.isEmpty(localeLink)) {
        if (localeLink.localeChurch) this.setState({ isLocaleChurchInputted: true });
        if (localeLink.link) this.setState({ isLinkInputted: true });
      }
    }
  }

  render() {
    let { localeChurches, unfilteredLocaleChurches, index, localeLink } = this.props;
    let { isLocaleChurchInputted, isLinkInputted } = this.state;

    localeChurches = unfilteredLocaleChurches;
    /*TODO: filter locale churches
    if (!_.isEmpty(localeLink) && localeLink.localeChurch) {
      localeChurches = unfilteredLocaleChurches;
      localeChurches = [];
      for (let i = 0; i < unfilteredLocaleChurches.length; i++) {
        if (unfilteredLocaleChurches[i]._id === localeLink.localeChurch) {
          localeChurches = [unfilteredLocaleChurches[i]];
          break;
        }
      }
    }
    */
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={24} lg={6}>
        <Form.Item
          validateStatus={isLinkInputted && !isLocaleChurchInputted ? "error" : ""}
          help={isLinkInputted && !isLocaleChurchInputted ? "Please select locale church" : ""}
        >
          <Select
            onChange={selected => {
              const isLocaleChurchInputted = !_.isEmpty(selected);
              this.setState({ isLocaleChurchInputted });
              this.props.handleLocaleChurchSelect(selected, index);
            }}
            style={{ width: "150px" }}
            allowClear={true}
            defaultValue={localeLink ? localeLink.localeChurch : null}
          >
            {localeChurches && localeChurches.map(item => {
              return (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              )
            })}
          </Select>
        </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
        <Form.Item
          validateStatus={isLocaleChurchInputted && !isLinkInputted ? "error" : ""}
          help={isLocaleChurchInputted && !isLinkInputted ? "Please input gathering link" : ""}
        >
          <Input
            onChange={e => {
              const isLinkInputted = !_.isEmpty(e.target.value);
              this.setState({ isLinkInputted });
              this.props.handleLinkInput(e.target.value, index);
            }}
            defaultValue={localeLink ? localeLink.link : null}
            style={{ width: 400 }}
          />
        </Form.Item>
        </Col>
      </Row>
    );
  }
}

class EditGathering extends Component {
  state = {
    name: '',
    type: '',
    startDateTime: null,
    endDateTime: null,
    gatheringLink: '',
    isActive: false,
    localeChurches: [],
    localeLinks: [],
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.getLocaleChurches()
      .then(res => {
        this.setState({
          localeChurches: res.locale_churches,
        })

    this.getGathering()
      .then(res => {
        const {
          name, type, startDateTime, endDateTime,
          active, gatheringLink, localeLinks,
        } = res.gathering;
        let selectedLocaleChurches = {};
        localeLinks.forEach((item, index) => {
          const { localeChurch, link } = item;
          selectedLocaleChurches[index] = { localeChurch, link }
        })
        this.setState({
          name,
          type,
          startDateTime,
          endDateTime,
          isActive: active,
          gatheringLink,
          loading: false,
          localeLinks,
          selectedLocaleChurches,
        });
      })
      .catch(err => console.log(err));
    })
  }

  getGathering = async () => {
    const _id = this.props.location.pathname.split('/')[2];
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getLocaleChurches = async () => {
    const response = await amsAPI.getUrl('/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const _id = this.props.location.pathname.split('/')[2];
    await amsAPI.fetchUrl(`/ams/gatherings/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        type: this.state.type,
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        gatheringLink: this.state.gatheringLink,
        active: this.state.isActive ? true : false,
        selectedLocaleChurches: this.state.selectedLocaleChurches,
      }),
    });
    message.success("Gathering successfully updated.");
    this.props.history.push('/gatherings');
  };

  handleLocaleChurchSelect = (selection, index) => {
    const { selectedLocaleChurches } = this.state;
    if (_.isEmpty(selectedLocaleChurches[index])) {
      selectedLocaleChurches[index] = {};
    }
    selectedLocaleChurches[index] = {
      link: selectedLocaleChurches[index].link,
      localeChurch: selection,
    };
    this.setState({ selectedLocaleChurches });
  };

  handleLinkInput = (value, index) => {
    const { selectedLocaleChurches } = this.state;
    if (_.isEmpty(selectedLocaleChurches[index])) {
      selectedLocaleChurches[index] = {};
    }
    selectedLocaleChurches[index] = {
      localeChurch: selectedLocaleChurches[index].localeChurch,
      link: value,
    };
    this.setState({ selectedLocaleChurches });
  };

  render() {
    const {
      name, type, isActive, startDateTime, endDateTime, gatheringLink,
      loading, localeChurches, selectedLocaleChurches, localeLinks,
    } = this.state;
    console.log('selectedLocaleChurches', selectedLocaleChurches)
    const disableSubmit = !name || !type || !startDateTime;

    let filteredlocaleChurches = [];
    if (!_.isEmpty(selectedLocaleChurches)) {
      filteredlocaleChurches = localeChurches.filter(item => {
        let hasMatch = false;
        const keys = Object.keys(selectedLocaleChurches);
        for (let i = 0; i < keys.length; i++) {
          if (selectedLocaleChurches[keys[i]].localeChurch === item._id) {
            hasMatch = true;
            break;
          }
        }
        return !hasMatch;
      });
    }

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }
    const gatheringEntries = Object.entries(constants.gatherings);
    const gatheringTypes = Object.entries(constants.gatheringTypes);
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item label="Gathering:">
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ name: value })}
                    defaultValue={name}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Type:">
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ type: value })}
                    defaultValue={type}
                  >
                    {gatheringTypes.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Start Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="Start Date Time"
                    onChange={(value) => this.setState({ startDateTime: value })}
                    disabledDate={disablePastDates}
                    defaultValue={startDateTime ? moment(startDateTime) : null}
                  />
                </Form.Item>
                <Form.Item label="End Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="End Date Time"
                    onChange={(value) => this.setState({ endDateTime: value })}
                    disabledDate={disablePastDates}
                    defaultValue={endDateTime ? moment(endDateTime) : null}
                  />
                </Form.Item>
                <Form.Item
                  label="Gathering Link:"
                >
                  <Input
                    onChange={(e) => this.setState({ gatheringLink: e.target.value })}
                    defaultValue={gatheringLink}
                    style={{ width: 500 }}
                  />
                </Form.Item>
                <Form.Item label="Is active?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={isActive}
                    onChange={(checked) => this.setState({ isActive: checked })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Locale Gathering Links</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form layout="inline">
                {_.range(this.state.localeChurches.length).map(i => {
                  return (
                    <LocaleChurchLink
                      localeChurches={filteredlocaleChurches}
                      unfilteredLocaleChurches={localeChurches}
                      handleLocaleChurchSelect={this.handleLocaleChurchSelect}
                      handleLinkInput={this.handleLinkInput}
                      localeLink={localeLinks[i]}
                      index={i}
                      key={i}
                    />
                  )
                })}
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    block
                    onClick={this.handleSubmit}
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmit}
                  >Update</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EditGathering;
