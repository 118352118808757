import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {
  Button, Col, Icon, Row, Spin, Table, Popconfirm, message,
} from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer'
import MyDocument from '../reports/MyDocument';

import ticketsAPI from '../../apis/ticketsAPI';

import 'antd/dist/antd.css';
import './List.css';

class LocaleReports extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      churchId: '',
      reports: [],
      localeChurchNames: [],
      loadingReports: false,
    };
  }

  createTableColumns = async () => {
    const { reports } = this.state;
    let churchLocaleNames = [];
    reports.forEach(item => {
      if (item.localeChurchId) {
        churchLocaleNames.push(item.localeChurchId.name);
      }
    });

    this.columns = [
      {
        title: 'No',
        dataIndex: 'rowKey',
        key: 'roKey._id',
        render: rowKey =>
          <NavLink
            style={{ padding: 10 }}
            to={`/locale_reports/${rowKey._id}/edit`}
          >
            <Icon type={"edit"}/>
          </NavLink>,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        render: subject => <span>{subject}</span>,
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        render: priority => <span>{priority}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => <span>{status}</span>,
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf" style={{ marginRight: '10px' }}>
              {({ blob, url, loading, error }) => (loading ? <Icon type="loading" /> : <Icon type="file-pdf" />)}
            </PDFDownloadLink>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingReports: true });
    this.getReports()
      .then(res => {
        this.setState({ reports: res.reports, loadingReports: false })
      })
  }

  getReports = async () => {
    const response = await ticketsAPI.getUrl('/tic/locale_reports')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (_id) => {
    await ticketsAPI.fetchUrl(`/tic/locale_reports/${_id}`, {
      method: 'DELETE',
    });
    message.success("Report successfully removed.")
    this.props.history.push('/locale_reports');
  };

  render() {
    this.createTableColumns();
    const { reports, loadingReports } = this.state;
    const loading = loadingReports;
    let modResult = [];
    let i = 0;
    reports.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(reports.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no reports.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the reports:`}</h3>
                  <Table columns={this.columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(LocaleReports);
