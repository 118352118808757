import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import {
  Button, Form, Select, Row, Col, PageHeader, Spin
} from 'antd';
import ReactQuill from 'react-quill';
import debounce from 'lodash/debounce';
import querystring from 'querystring';

import * as constants from '../../helpers/constants';

import amsAPI from '../../apis/amsAPI';
import ticketsAPI from '../../apis/ticketsAPI';

import 'react-quill/dist/quill.snow.css';
import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
    lg: { span: 16 },
  },
};

const contentItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class DraftLocaleReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)
    this.state = {
      body: '',
      text: '',
      draft: {},
      members: [],
      localeChurches: [],
      loadingDraft: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateDraft = this.updateDraft.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingDraft: true });
    this.getDraft()
      .then(res => {
        this.setState({ draft: res.draft, loadingDraft: false })
      })
  }

  getDraft = async () => {
    const { _id } = this.props.match.params;
    const response = await ticketsAPI.getUrl(`/tic/drafts/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { _id } = this.props.match.params;
    const { draft } = this.state;

    await ticketsAPI.fetchUrl(`/tic/locale_reports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subect: draft.subject,
        author: draft.author,
        status: draft.status,
        priority: draft.priority,
        body: draft.body,
        localeChurch: draft.localeChurch,
        draftId: _id,
      }),
    });
    this.props.history.push('/locale_reports');
  }

  updateDraft = async (updatedInfo) => {
    const currentInfo = this.state.draft;
    this.setState({
      draft: { ...currentInfo, ...updatedInfo }
    });
  };

  saveContent = debounce(async (body) => {
    const { draft } = this.state;
    const { _id } = this.props.match.params;
    const response = await ticketsAPI.fetchUrl(`/tic/drafts/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...draft, body
      }),
    });

    const resp_body = await response.json();
    if (response.status !== 200) throw Error(resp_body.message);
    console.log('resp_body', resp_body)
  }, 1000);

  onChange = (value) => {
    if (value) {
      this.saveContent(value);
      this.updateDraft({ body: value });
    }
  }

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  render() {
    const { t } = this.props;
    const priorities = Object.entries(constants.priorities);
    const statuses = Object.entries(constants.statuses);
    const { draft, members, localeChurches, loadingDraft } = this.state;
    const loading = loadingDraft;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Locale:">
                    <Select
                        showSearch
                        placeholder="Select a locale"
                        dropdownMatchSelectWidth={false}
                        onChange={value => this.updateDraft({ localeChurch: value })}
                        defaultValue={draft.localeChurch ? draft.localeChurch.name : ""}
                      >
                        {localeChurches.map(item => {
                          return <Option key={item._id} value={item._id}>{item.name}</Option>
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Coordinator:"
                  >
                    <Select
                      showSearch
                      placeholder={t("Input coordinator name or id")}
                      dropdownMatchSelectWidth={false}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearch}
                      onChange={value => this.updateReport({ author: value })}
                      notFoundContent={null}
                      defaultValue={draft.author ? draft.author.name : ""}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Priority:">
                    <Select
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateDraft({ priority: value })}
                      defaultValue={draft.priority}
                    >
                      {priorities.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Status:">
                    <Select
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateDraft({ status: value })}
                      defaultValue={draft.status}
                    >
                      {statuses.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form
                  {...contentItemLayout}
                  onSubmit={this.handleSubmit}
                >
                  <Form.Item label="Content:">
                    <ReactQuill
                      value={draft ? draft.body : ''}
                      onChange={this.onChange}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Submit</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(withTranslation()(DraftLocaleReport));
