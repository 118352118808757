import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import {
  Button, Form, Select, Row, Col, PageHeader, Spin, Input
} from 'antd';
import ReactQuill from 'react-quill';
import debounce from 'lodash/debounce';
import querystring from 'querystring';

import * as constants from '../../helpers/constants';

import amsAPI from '../../apis/amsAPI';
import ticketsAPI from '../../apis/ticketsAPI';

import 'react-quill/dist/quill.snow.css';
import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
    lg: { span: 16 },
  },
};

const contentItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class EditLocaleReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)
    this.state = {
      content: '',
      text: '',
      report: {},
      members: [],
      localeChurches: [],
      loadingLocaleChurches: false,
      loadingReport: false,
    }

    this.updateReport = this.updateReport.bind(this);
  }
  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingLocaleChurches: true, loadingReport: true });
    this.getLocaleChurches()
      .then(res => {
        this.setState({ localeChurches: res.locale_churches, loadingLocaleChurches: false })
      });
    this.getReport()
      .then(res => {
        this.setState({ report: res.report, loadingReport: false })
      })
  }

  getLocaleChurches = async () => {
    const response = await amsAPI.getUrl('/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getReport = async () => {
    const { _id } = this.props.match.params;
    const response = await ticketsAPI.getUrl(`/tic/locale_reports/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateReport = async (updatedInfo) => {
    const currentInfo = this.state.report;
    this.setState({
      report: { ...currentInfo, ...updatedInfo }
    });
  };

  saveContent = debounce(async (body) => {
    const { report } = this.state;
    const { _id } = this.props.match.params;
    const response = await ticketsAPI.fetchUrl(`/tic/locale_reports/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...report, body
      }),
    });

    const resp_body = await response.json();
    if (response.status !== 200) throw Error(resp_body.message);
  }, 1000);

  onChange = (value) => {
    if (value) {
      this.updateReport({ content: value });
      this.saveContent(value);
    }
  }

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  render() {
    const { t } = this.props;
    const priorities = Object.entries(constants.priorities);
    const statuses = Object.entries(constants.statuses);
    const { report, members, localeChurches, loadingLocaleChurches, loadingReport } = this.state;
    const loading = loadingLocaleChurches || loadingReport;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Locale:">
                    <Select
                        showSearch
                        placeholder="Select a locale"
                        dropdownMatchSelectWidth={false}
                        onChange={value => this.updateReport({ localeChurch: value })}
                        defaultValue={report.localeChurch ? report.localeChurch.name : ""}
                      >
                        {localeChurches.map(item => {
                          return <Option key={item._id} value={item._id}>{item.name}</Option>
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Coordinator:"
                  >
                    <Select
                      showSearch
                      placeholder={t("Input coordinator name or id")}
                      dropdownMatchSelectWidth={false}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearch}
                      onChange={value => this.updateReport({ author: value })}
                      notFoundContent={null}
                      defaultValue={report.author ? report.author.name : ""}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Priority:">
                    <Select
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateReport({ priority: value })}
                      defaultValue={report.priority}
                    >
                      {priorities.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Status:">
                    <Select
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateReport({ status: value })}
                      defaultValue={report.status}
                    >
                      {statuses.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form
                  {...contentItemLayout}
                >
                  <Form.Item label="Subject:">
                    <Input
                      onChange={e => this.updateReport({ subject: e.target.value })}
                      defaultValue={report.subject}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form
                  {...contentItemLayout}
                >
                  <Form.Item label="Content:">
                    <ReactQuill
                      defaultValue={report.body}
                      onChange={this.onChange}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Submit</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(withTranslation()(EditLocaleReport));
