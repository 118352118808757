import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {
  Col, Icon, Row, Spin, Table, message, Button, Breadcrumb,
} from 'antd';
import { RemittancesFilter } from '../../filters';

import * as qs from 'query-string';
import moment from 'moment';

import financeAPI from '../../../apis/financeAPI';

import 'antd/dist/antd.css';
import './List.css';

class Remittances extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      churchId: '',
      startDate: '',
      endDate: '',
      filter: {},
      items: [],
      members: [],
      bankAccounts: [],
      selectedRows: [],
      selectedRowKeys: [],
      initialSearch: true,
      loading: false,
    };
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Remitter',
        dataIndex: 'remitter',
        key: 'remitter',
        width: 150,
        render: remitter => <span>{remitter ? remitter.name : null}</span>,
      },
      {
        title: 'Receipt No',
        dataIndex: 'receiptNumber',
        key: 'receiptNumber',
        width: 100,
        defaultSortOrder: 'descend',
        sortDirections: ['descend'],
        sorter: (a, b) => a.receiptNumber.localeCompare(b.receiptNumber),
        render: receiptNumber => <span>{receiptNumber}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: status => <span>{status ? status.toUpperCase() : null}</span>,
      },
      {
        title: 'Recipient / Bank Account',
        dataIndex: 'recipient',
        key: 'recipient',
        width: 100,
        render: (text, record) => (
        <span>
          {record.remitMethod === "mail" ?
            record.recipient ? record.recipient.name : null
            :
            record.bankAccount ? record.bankAccount.name : null
          }
        </span>
        )
      },
      {
        title: 'Breakdown',
        dataIndex: 'notes',
        key: 'notes',
        width: 250,
        render: notes => <span style={{whiteSpace: "pre-wrap"}}>{notes}</span>
      },
      {
        title: 'Date Remitted',
        dataIndex: 'remittanceDate',
        key: 'remittanceDate',
        sortDirections: ['descend'],
        sorter: (a, b) => moment(a.remittanceDate).unix() - moment(b.remittanceDate).unix(),
        render: remittanceDate => <span>{remittanceDate ? moment(remittanceDate).format("YYYY-MM-DD") : null}</span>,
      },
      {
        title: 'Date Received',
        dataIndex: 'receiptDate',
        key: 'receiptDate',
        render: receiptDate => <span>{receiptDate ? moment(receiptDate).format("YYYY-MM-DD") : null}</span>,
      },
      {
        title: 'Do',
        fixed: 'right',
        width: 30,
        key: 'action',
        render: record =>
          <span>
            <NavLink
              to={"/#"}
              onClick={e => e.preventDefault()}
            >
              <Button
                block
                type="link"
                onClick={(e) => {
                  const { _id } = record;
                  financeAPI.fetchUrl(`/fin/remittances/${_id}/pdf`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Cache-Control': 'no-cache',
                    },
                    body: JSON.stringify({
                      _id,
                      filename: _id,
                      content: `Testing Content ${_id}`
                    }),
                  }).then(async res => {
                    if (res.status === 200) {
                      const element = document.createElement("a");
                      const blob = await res.blob();
                      const file = new Blob(
                        [blob],
                        {type: 'application/pdf'}
                      );
                      element.href = URL.createObjectURL(file);

                      // Save the Data
                      const fileName = `${record.receiptNumber}.pdf`;
                      element.download = fileName;
                      document.body.appendChild(element); // Required for this to work in FireFox
                      element.click();
                    } else {
                      const error = new Error(res.error);
                      throw error;
                    }
                  })
                  .catch(err => {
                    console.error(err);
                    message.error('Error submitting.');
                  });
                }}
              >
                <Icon type="file-pdf" />
              </Button>
            </NavLink>
            {this.props.isAudit ?
              <div>
                <NavLink
                  to={`/remittances/${record._id}/show`}
                >
                  <Button block type="link">
                    <Icon type={"eye"}/>
                  </Button>
                </NavLink>
              </div>
            :
              <div>
                <NavLink
                  to={`/remittances/${record._id}/edit`}
                >
                  <Button block type="link">
                    <Icon type={"edit"}/>
                  </Button>
                </NavLink>
                <NavLink
                  to={`/remittances/${record._id}/generate`}
                >
                  <Button block type="link">
                    <Icon type={"exclamation-circle"} theme="twoTone" twoToneColor="#eb2f96"/>
                  </Button>
                </NavLink>
              </div>
            }
              <NavLink
                to={`/remittances/${record._id}/history`}
              >
                <Button block type="link">
                  <Icon type={"file-text"}/>
                </Button>
              </NavLink>
          </span>,
      },
    ];
  }

  getItems = async (query) => {
    this.setState({ loading: true });
    const queryWithStatus = { ...query, status: this.props.status };
    const response = await financeAPI.getUrl(`/fin/remittances/lc?${qs.stringify(queryWithStatus)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await financeAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  handleDelete = async (_id) => {
    await financeAPI.fetchUrl(`/fin/remittances/${_id}`, {
      method: 'DELETE',
    });
    message.success("Report successfully removed.")
    if (this.props.isAudit) {
      this.props.history.push('/remittances_closed');
    } else {
      this.props.history.push('/remittances');
    }
  };

  handleRangeChange = (date, dateString) => {
    this.setState({ startDate: dateString[0], endDate: dateString[1]})
  }

  setFilter = async (updatedInfo) => {
    const currentInfo = this.state.filter;
    this.setState({
      filter: { ...currentInfo, ...updatedInfo }
    });
  };

  filter = () => {
    this.setState({ initialSearch: false });
    const { userMemberId } = this.props.userInfo;
    const { remitMethod } = this.props;
    const { memberId, receiptNumber, bankAccount, startDate, endDate } = this.state.filter;

    const query = {
      userMemberId, memberId, receiptNumber, startDate, endDate,
    };

    if (remitMethod) query.remitMethod = remitMethod;
    if (remitMethod === "bank") query.bankAccount = bankAccount;

    this.getItems(query)
      .then(res => {
        this.setState({ items: res.remittances, loading: false })
      })
  }

  downloadFile = async () => {
    const { selectedRowKeys } = this.state;
    financeAPI.fetchUrl(`/fin/remittances/pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({ _ids: selectedRowKeys }),
    }).then(async res => {
      if (res.status === 200) {
        const element = document.createElement("a");
        const blob = await res.blob();
        const file = new Blob(
          [blob],
          {type: 'application/pdf'}
        );
        element.href = URL.createObjectURL(file);

        // Save the Data
        const fileName = `Remittance Report_${moment().format('YYYYMMDD_hhmmss')}.pdf`;
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error submitting.');
    });
  }

  render() {
    this.createTableColumns();
    const { filter, items, loading, selectedRowKeys } = this.state;
    const { account, remitMethod, userInfo } = this.props;
    const { bankAccount } = filter;
    let modResult = [];
    let i = 0;
    items.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="start" style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={24} lg={24}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/">
                    Home
                  </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/finance">
                    Finance Dashboard
                  </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to={this.props.isAudit ? "/remittances_closed" : "/remittances"}>
                    Remittance Home {this.props.isAudit ? " (Closed)" : ""}
                  </NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>{`Remittance ${this.props.isAudit ? "(Closed)" : ""} List Page : ${account ? account.toUpperCase() : remitMethod ? remitMethod.toUpperCase() : "Locale Church"}`}</h3>
            </Col>
          </Row>
          <RemittancesFilter
            setFilter={this.setFilter}
            handleFilter={this.filter}
            downloadFile={this.downloadFile}
            data={selectedRowKeys}
            downloadText={"Download PDFs"}
            bankAccount={bankAccount}
            remitMethod={this.props.remitMethod}
            isLocaleChurchAccount={this.props.isLocaleChurchAccount}
            displayRefresh={false}
            userInfo={userInfo}
          />
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={20}>
              {(items.length === 0) ?
                <div>
                  <h3>{this.state.initialSearch ? "" : "Sorry, but there are no remittances."}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the remittances queried:`}</h3>
                  <Table
                    rowSelection={this.rowSelection}
                    columns={this.columns}
                    dataSource={modResult}
                    scroll={{ x: 1000 }}
                    pagination={false}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Remittances);
