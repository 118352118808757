import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import { Button, Col, Row, Spin, Table, Form, Select, DatePicker } from 'antd';
import { stringify } from 'query-string';

import moment from 'moment';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './List.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class Activities extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedGatheringType: '',
      activityDate: '',
      activities: [],
      loading: false,
    };

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) => (
          <span>
            <NavLink
              to={`/activities/${record._id}/edit`}
            >
              {constants.activities[record.name]}
            </NavLink>
          </span>
        ),
      },
      {
        title: 'Ministry',
        dataIndex: 'ministry',
        key: 'ministry',
        render: (text, record) => (
          <span>{record.ministry.name}</span>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: type => <span>{type}</span>
      },
      {
        title: 'Date/Time',
        dataIndex: 'startDateTime',
        key: 'startDateTime',
        sortDirections: ['ascend'],
        sorter: (a, b) => moment(a.startDateTime).unix() - moment(b.startDateTime).unix(),
        render: startDateTime => (
          <span>{`${moment(startDateTime).format("MMM.DD, YYYY (ddd) h:mmA")}`}</span>
        ),
      },
      {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        render: active => <span>{ active ? "Y" : "N"}</span>,
        sortDirections: ['ascend'],
        sorter: (a, b) => {
          let aY = a.active ? "Y" : "N";
          let bY = b.active ? "Y" : "N";
          return aY.localeCompare(bY);
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink
              to={`/activities/${record._id}/attendees`}
            >
              <Button type="link" icon="team" />
            </NavLink>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({ activities: res.data, loading: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.callApi()
        .then(res => this.setState({ activities: res.data, loading: false }))
        .catch(err => console.log(err));
    }
  }

  callApi = async (query) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(`/ams/activities?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleFilter = async () => {
    const query = {
      name: this.state.selectedActivityType,
      activityDate: this.state.activityDate,
    };
    this.callApi(query)
      .then(res => this.setState({ activities: res.data, loading: false }))
      .catch(err => console.log(err));
  };

  render() {
    const { selectedActivityType, activityDate, activities, loading } = this.state;
    let modResult = [];
    if (activities && activities.length > 0) {
      let i = 0;
      activities.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }
    const activityEntries = Object.entries(constants.activities);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Activities Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Activity:">
                  <Select
                    allowClear
                    placeholder="Select the gathering type"
                    onChange={value => this.setState({ selectedActivityType: value })}
                    value={selectedActivityType}
                  >
                    {activityEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => this.setState({ activityDate: dateString})}
                    defaultValue={activityDate ? moment(activityDate) : null}
                  />
                  <Button
                    type="primary"
                    style={{marginLeft: '10px'}}
                    onClick={this.handleFilter}
                  > Filter</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(activities.length === 0) ?
                <div style={{textAlign: 'center'}}>
                  <h3>{`Sorry, no activites found.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here's are the list of activities:`}</h3>
                    <Table
                      columns={this.columns}
                      dataSource={modResult}
                      pagination={{hideOnSinglePage: true}}
                    />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Activities);