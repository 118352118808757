import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
  Form, Select, Button, Row, Col, Icon, DatePicker, Input,
} from 'antd';

import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import financeAPI from '../../apis/financeAPI';

import 'antd/dist/antd.css';
import './Filter.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

let timeout;
let currentValue;

function fetch(params, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = params.value;

  async function fake() {
    const { value } = params;
    const query = { q: value };
    amsAPI.getUrl(`/ams/members?${qs.stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class RemittancesFilter extends Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
  };

  constructor() {
		super();
    this.state = {
      startDate: '',
      endDate: '',
      members: [],
      bankAccounts: [],
    };
    this.handleMemberSearch = this.handleMemberSearch.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    let query = {};
    const { isLocaleChurchAccount, userInfo } = this.props;
    if (isLocaleChurchAccount) {
      query.isLocaleChurchAccount = isLocaleChurchAccount;
      query.localeChurch = userInfo.localeChurchId;
      query.isActive = true;
    }
    this.getBankAccounts(query)
      .then(res => {
        this.setState({ bankAccounts: res.data, bankAccount: res.data[0]._id });
        this.props.setFilter({ bankAccount: res.data[0]._id });
      })
      .catch(err => console.log(err));
  }

  getBankAccounts = async (query) => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts?${qs.stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleMemberSearch = (value) => {
    if (value) {
      const params = { value };
      fetch(params, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  }

  render() {
    const { bankAccount, members, bankAccounts } = this.state;
    const { data, remitMethod } = this.props;
    const hasResult = data.length > 0;
    const displayBankAccounts = (this.props.bankAccount || this.props.isLocaleChurchAccount) && remitMethod === "bank";

    return (
      <div>
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="primary"
              onClick={this.props.downloadFile}
              disabled={!hasResult}
            >{this.props.downloadText || "Download"}</Button>
            {this.props.displayRefresh &&
              <Button
                type="primary"
                onClick={this.props.refresh}
              > <Icon type="reload"/></Button>
            }
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form {...formItemLayout}>
              <Form.Item label="Name:">
                <Select
                  showSearch
                  labelInValue
                  optionFilterProp="label"
                  optionLabelProp="label"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(value) => this.handleMemberSearch(value)}
                  onChange={value => this.props.setFilter({ memberId: value })}
                  notFoundContent={null}
                >
                  {members.map(item => {
                    return (
                      <Option key={item._id} value={item._id} label={item.name}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Receipt Number:">
                <Input onChange={e => this.props.setFilter({ receiptNumber: e.target.value })} />
              </Form.Item>
              <Form.Item label="Range:">
                <RangePicker
                  format={'YYYY/MM/DD'}
                  onChange={(date, dateString) => this.props.setFilter({ startDate: dateString[0], endDate: dateString[1]}) }
                />
              </Form.Item>
              {displayBankAccounts &&
                <Form.Item label={"Bank Account"}>
                  <Select
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                      this.setState({ bankAccount: value });
                      this.props.setFilter({ bankAccount: value });
                    }}
                    value={bankAccount}
                  >
                    {bankAccounts.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              }
              <Form.Item>
                <Button
                  style={{marginLeft: '10px'}}
                  onClick={this.props.handleFilter}
                  type="primary"
                >Search</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RemittancesFilter;
