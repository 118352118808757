import React, { Component } from 'react';
import {
  Form, Button, DatePicker, Select, Row, Col, Spin, Switch, Input, message
} from 'antd';

import moment from 'moment';
import amsAPI from '../../apis/amsAPI';
import disablePastDates from '../../helpers/functions';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Activity.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditActivity extends Component {
  state = {
    record: {},
    ministries: [],
    loadingRecord: false,
    loadingMinistries: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true, loadingMinistries: true });
    this.getRecord()
      .then(res => {
        this.setState({ record: res.data, loadingRecord: false });
      })
      .catch(err => console.log(err));
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
  }

  getRecord = async () => {
    const { _id } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/activities/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { _id } = this.props.match.params;
    const { record } = this.state;
    await amsAPI.fetchUrl(`/ams/activities/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...record}),
    });
    message.success("Activity successfully updated.");
    this.props.history.push('/activities');
  };

  render() {
    const { record, loadingRecord, loadingMinistries, ministries } = this.state;
    const loading = loadingRecord || loadingMinistries;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const { name, ministry, startDateTime, endDateTime, active, link } = record;
    const activityEntries = Object.entries(constants.activities);
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Activity:">
          <Select
            showSearch
            placeholder="Select an activity"
            dropdownMatchSelectWidth={false}
            style={{ width: 240 }}
            onChange={value => this.updateRecord({ name: value })}
            defaultValue={name}
          >
            {activityEntries.map(([id, name]) =>
              <Option key={id} value={id}>{name}</Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Ministry">
          <Select
            placeholder="Select a ministry"
            dropdownMatchSelectWidth={false}
            style={{ width: 240 }}
            onChange={value => this.updateRecord({ ministry: value })}
            defaultValue={ministry}
          >
            {ministries.map(item => {
              return <Option key={item._id} value={item._id}>{item.name}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Start Date:">
          <DatePicker
            showToday
            format="YYYY-MM-DD h:mm a"
            showTime={{ use12Hours: true, format: "h:mm a" }}
            placeholder="Start Date Time"
            onChange={(value) => this.updateRecord({ startDateTime: value })}
            disabledDate={disablePastDates}
            defaultValue={startDateTime ? moment(startDateTime) : null}
          />
        </Form.Item>
        <Form.Item label="End Date:">
          <DatePicker
            showToday
            format="YYYY-MM-DD h:mm a"
            showTime={{ use12Hours: true, format: "h:mm a" }}
            placeholder="End Date Time"
            onChange={(value) => this.updateRecord({ endDateTime: value })}
            disabledDate={disablePastDates}
            defaultValue={endDateTime ? moment(endDateTime) : null}
          />
        </Form.Item>
        <Form.Item
          label="Link:"
        >
          <Input
            onChange={(e) => this.updateRecord({ link: e.target.value })}
            defaultValue={link}
          />
        </Form.Item>
        <Form.Item label="Is active?">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={active}
            onChange={(checked) => this.updateRecord({ active: checked })}
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Update</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default EditActivity;
